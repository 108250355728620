import * as yup from 'yup';
import Divider from '@mui/material/Divider';
import Fab from '@mui/material/Fab';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { Controller, useForm } from 'react-hook-form';
import { colorValidator } from '@fuse/hooks/AvatarColor';
import { format, parseISO } from 'date-fns';
import { motion } from 'framer-motion';
import { roleChecker } from 'app/store/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import { addTag, selectTagById, setDeleteModalProps, setTag } from '../store/tagsSlice';

const defaultValues = {
  name: '',
  color: '#540AF0',
};

function TagsSidebarContent(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation('managementApp');
  const checkRole = useSelector(roleChecker);
  const { wsId, tagId } = useParams();
  const [editMode, setEditMode] = useState(false);
  const tag = useSelector((state) => selectTagById(state, tagId));

  const onClose = () => navigate(`..`, { replace: true });

  const onEdit = () => {
    reset(tag);
    setEditMode(true);
  };

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    name: yup.string().required(t('NAME_CANNOT_EMPTY')),
  });

  const { control, formState, getValues, reset, watch, setValue } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const handleColor = (e) => {
    if (colorValidator.test(e.target.value)) {
      setValue('color', e.target.value);
    }
  };

  const saveTag = () => dispatch(setTag({ wsId, tag: getValues() }));

  const createTag = async () => {
    const {
      payload: [{ id }],
    } = await dispatch(addTag({ wsId, tag: getValues() }));
    navigate(`../${id}${window.location.search}`, { replace: true });
  };

  const openDeleteModal = () => dispatch(setDeleteModalProps({ open: true, tagId }));

  useEffect(() => {
    if (tagId === 'new') {
      setEditMode(true);
      reset(defaultValues);
    } else {
      setEditMode(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tag, tagId]);

  const { isValid, dirtyFields, errors } = formState;

  if (!tag && tagId !== 'new') {
    return null;
  }

  return (
    <motion.div
      initial={{ y: 50, opacity: 0.8 }}
      animate={{ y: 0, opacity: 1, transition: { delay: 0.3 } }}
      className="file-details px-32 sm:px-32"
    >
      <div className="flex items-center justify-end w-full ">
        <IconButton className="" size="large" onClick={onClose}>
          <FuseSvgIcon>heroicons-solid:x</FuseSvgIcon>
        </IconButton>
      </div>
      {editMode || tagId === 'new' ? (
        <form
          id="tag-form"
          name="tagForm"
          noValidate
          className="flex flex-col justify-center w-full mt-32"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                id="tag-name"
                className="mb-24"
                label={t('NAME')}
                autoFocus
                error={!!errors.name}
                helperText={errors?.name?.message}
                variant="outlined"
                required
                fullWidth
              />
            )}
          />
          <Typography variant="caption" className="font-medium" display="inline">
            {t('SELECT_A_COLOR')}
          </Typography>
          <Controller
            name="color"
            control={control}
            render={({ field }) => (
              <div className="h-80 w-full my-24 relative">
                <input
                  autoComplete="off"
                  id="tag-color"
                  {...field}
                  type="color"
                  className="colorSelector w-full h-full  shadow-15  "
                  onChange={handleColor}
                  placeholder="sdsddsadd"
                />

                <Typography
                  className="absolute flex items-center justify-center left-0 right-0 top-0 bottom-0 text-center pointer-events-none"
                  sx={{ color: '#FFF' }}
                  variant="h6"
                >
                  {watch('name')}
                </Typography>
              </div>
            )}
          />
          <div className="absolute right-12 bottom-12 flex gap-10">
            {tagId === 'new' ? (
              <Tooltip title={t('CREATE')} placement="top">
                <Fab
                  color="secondary"
                  size="small"
                  aria-label="edit"
                  onClick={createTag}
                  disabled={!isValid}
                >
                  <FuseSvgIcon size={20}>material-solid:save</FuseSvgIcon>
                </Fab>
              </Tooltip>
            ) : (
              <Tooltip title={t('SAVE')} placement="top">
                <Fab
                  color="secondary"
                  size="small"
                  aria-label="edit"
                  onClick={saveTag}
                  disabled={!isValid}
                >
                  <FuseSvgIcon size={20}>material-solid:save</FuseSvgIcon>
                </Fab>
              </Tooltip>
            )}
          </div>
        </form>
      ) : (
        <div className="mt-32 w-full h-full">
          <Typography
            className="w-full py-40 shadow-15 rounded-12 text-center"
            sx={{ backgroundColor: tag?.color, color: '#FFF' }}
            variant="h6"
          >
            {tag?.name}
          </Typography>
          {checkRole('platform_support') && (
            <>
              <div className="w-full py-4 pt-12">
                <Divider />
              </div>
              <div>
                <Typography variant="caption" className="font-medium">
                  {t('CREATION_DATE')}:
                </Typography>
                <Typography variant="caption">
                  {format(parseISO(tag.date_created), 'dd/MM/y hh:mm aaaa ')}
                </Typography>
              </div>
              <div>
                <Typography variant="caption" className="font-medium">
                  {t('EDITION_DATE')}:
                </Typography>
                <Typography variant="caption">
                  {format(parseISO(tag.date_edited), 'dd/MM/y hh:mm aaaa ')}
                </Typography>
              </div>
              <div>
                <Typography variant="caption" className="font-medium">
                  ID:
                </Typography>
                <Typography variant="caption">{tag.id}</Typography>
              </div>
            </>
          )}
          <div className="absolute right-24 bottom-24 flex gap-10">
            <Tooltip title={t('EDIT')} placement="top">
              <Fab color="secondary" size="small" aria-label="edit" onClick={onEdit}>
                <FuseSvgIcon size={20}>heroicons-solid:pencil</FuseSvgIcon>
              </Fab>
            </Tooltip>
            <Fab color="error" size="small" aria-label="remove" onClick={openDeleteModal}>
              <FuseSvgIcon size={20}>material-solid:delete</FuseSvgIcon>
            </Fab>
          </div>
        </div>
      )}
    </motion.div>
  );
}

export default TagsSidebarContent;
