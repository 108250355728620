/* eslint-disable camelcase */
import axios from 'axios';
import ApiRoutes from 'src/app/services/ApiRoutes';
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { showMessage } from 'app/store/fuse/messageSlice';
import { t } from 'i18next';
import { upsertGeofences } from './geofencesSlice';

export const getGeofencesRecordsList = createAsyncThunk(
  'LocationApp/MapData/GeofencesRecords/getGeofencesRecordsList',
  async ({ ws, params }, { dispatch, getState }) => {
    const { params: oldParams } = getState().LocationApp.GeofencesRecords;
    const currentParams = { ...oldParams, ...params };

    const { data } = await axios.get(ApiRoutes.getGeofencesRecords(ws, currentParams));

    if (data.length === 0)
      dispatch(
        showMessage({ message: t('locationApp:NO_GEOFENCESRECORDS_FOUND'), variant: 'warning' })
      );

    // geofences markers data
    const geofences = data.results
      .map(({ geofence_data }) => geofence_data)
      .filter((geofence, index, self) => index === self.findIndex(({ id }) => id === geofence.id));

    dispatch(setGeofences(geofences.map(({ id }) => id)));
    dispatch(upsertGeofences(data.results.map(({ geofence_data }) => geofence_data)));

    dispatch(setPage(currentParams.page));
    dispatch(setParams(currentParams));
    dispatch(setNumOfPages(Math.ceil(data.count / 100 || 0)));

    return data.results;
  }
);

const GeofencesRecordsAdapter = createEntityAdapter({});

export const selectGeofencesRecords = ({ LocationApp }) => LocationApp.GeofencesRecords;

export const { selectAll: selectGeofencesRecordsLists } =
  GeofencesRecordsAdapter.getSelectors(selectGeofencesRecords);
const initialState = GeofencesRecordsAdapter.getInitialState({
  numOfPages: 0,
  page: 1,
  loading: false,
  geofences: [],
  params: {},
});

export const geofenceInReports =
  ({
    LocationApp: {
      GeofencesRecords: { geofences },
    },
  }) =>
  (idGeofence) =>
    geofences.includes(idGeofence);

const GeofencesRecordsSlice = createSlice({
  name: 'LocationApp/MapData/GeofencesRecords',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setNumOfPages: (state, action) => {
      state.numOfPages = action.payload;
    },
    setParams: (state, action) => {
      state.params = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setReferenceRoute: (state, action) => {
      state.routeReference = action.payload;
    },
    setGeofences: (state, action) => {
      state.geofences = action.payload;
    },
    resetGeofencesRecordsState: () => initialState,
  },
  extraReducers: {
    [getGeofencesRecordsList.fulfilled]: GeofencesRecordsAdapter.setAll,
  },
});

export const {
  setLoading,
  setNumOfPages,
  setParams,
  setPage,
  setReferenceRoute,
  resetGeofencesRecordsState,
  setGeofences,
} = GeofencesRecordsSlice.actions;

const { reducer } = GeofencesRecordsSlice;

export default reducer;
