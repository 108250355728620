/* eslint-disable camelcase */
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FuseLoading from '@fuse/core/FuseLoading';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import FuseUtils from '@fuse/utils';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { Controller, useForm } from 'react-hook-form';
import { format, parseISO } from 'date-fns';
import { lighten } from '@mui/material/styles';
import { motion } from 'framer-motion';
import { roleChecker } from 'app/store/userSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  addCsvLoad,
  getCsvLoadErrors,
  selectCsvLoadById,
  setCsvLoadErrorsModalProps,
} from '../../store/csvLoadsSlice';

const defaultValues = {
  id: null,
  type: '',
  file_data: null,
};

function CvsLoadSidebarContent(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const checkRole = useSelector(roleChecker);

  const { t } = useTranslation('managementApp');
  const { wsId, csvLoadId } = useParams();
  const [isLoading, setLoading] = useState(false);

  const csvLoad = useSelector((state) => selectCsvLoadById(state, csvLoadId));

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    type: yup.string().required(),
    file_data: yup.object().required(),
  });

  const { control, reset, watch, setValue, formState, getValues } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const onClose = () => navigate(`..${window.location.search}`, { replace: true });

  const onDropCsvFiles = (e) => {
    e.preventDefault();
    console.log(e);
    if (!e.dataTransfer.files[0]) return;
    if (
      !(
        e.dataTransfer.files[0].type === 'text/csv' ||
        e.dataTransfer.files[0].type === 'application/vnd.ms-excel'
      )
    ) {
      dispatch(showMessage({ message: t('FILE_MUST_BE_CSV'), variant: 'error' }));
      return;
    }
    if (e.dataTransfer.files[0].size > 1e9) {
      dispatch(showMessage({ message: t('VERY_LARGE_FILE'), variant: 'error' }));
      return;
    }

    setValue(
      'file_data',
      {
        file: e.dataTransfer.files[0],
        name: e.dataTransfer.files[0].name,
        size: FuseUtils.getFileSizeLabel(e.dataTransfer.files[0].size),
      },
      { shouldValidate: true }
    );
  };

  const onChangeCsvFile = ({ target }) => {
    if (!target.files[0]) return;
    if (target.files[0].size > 1e9) {
      dispatch(showMessage({ message: t('VERY_LARGE_FILE'), variant: 'error' }));
      return;
    }

    setValue(
      'file_data',
      {
        file: target.files[0],
        name: target.files[0].name,
        size: FuseUtils.getFileSizeLabel(target.files[0].size),
      },
      { shouldValidate: true }
    );
  };

  const uploadCsv = async () => {
    try {
      setLoading(true);
      const [{ id }] = await dispatch(addCsvLoad({ wsId, csvLoad: getValues() })).unwrap();
      navigate(`../${id}${window.location.search}`, { replace: true });
    } catch (error) {
      if (error.code === 'ERR_NETWORK') {
        dispatch(showMessage({ message: t('ERR_UPLOAD_FILE'), variant: 'error' }));
        setValue('file_data', null, { shouldValidate: true });
      }
      console.log(error);
    }
    setLoading(false);
  };

  const openCsvLoadErrorsModal = () => {
    dispatch(getCsvLoadErrors({ csvLoad }));
    dispatch(setCsvLoadErrorsModalProps({ open: true }));
  };

  useEffect(() => {
    if (csvLoadId === 'new') {
      reset({
        id: uuidv4(),
        type: 'A',
        file: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [csvLoadId]);

  if (!csvLoadId || (csvLoadId !== 'new' && !csvLoad)) {
    return null;
  }

  if (isLoading) {
    return (
      <div className="h-full flex-1 flex items-center">
        <FuseLoading />
      </div>
    );
  }

  return (
    <motion.div
      initial={{ y: 50, opacity: 0.8 }}
      animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
      className="flex flex-col flex-1 h-full w-full"
    >
      <div className="flex items-center justify-end w-full h-full">
        <IconButton size="medium" onClick={onClose}>
          <FuseSvgIcon>heroicons-solid:x</FuseSvgIcon>
        </IconButton>
      </div>
      {csvLoadId === 'new' ? (
        <form
          name="CsvForm"
          noValidate
          className="flex flex-col justify-center w-full mt-12 px-10"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Controller
            name="type"
            control={control}
            render={({ field }) => (
              <FormControl className="mb-24" fullWidth>
                <InputLabel size="small" id="projectId-selection">
                  {t('TYPE_LOAD')}
                </InputLabel>
                <Select
                  {...field}
                  fullWidth
                  size="small"
                  labelId="projectId-selection"
                  id="projectId-selection"
                  name="projectId-selection"
                  variant="outlined"
                  label={t('TYPE_LOAD')}
                  sx={{
                    '&:hover': {
                      '& .MuiIconButton-root ': {
                        display: field.value ? 'inherit' : 'none',
                      },
                    },
                    '& .MuiIconButton-root ': {
                      display: 'none',
                    },
                  }}
                >
                  {/* <MenuItem value="G">
                    <div className="flex items-center gap-5">
                      <FuseSvgIcon color="inherit" size={18}>
                        material-solid:location_searching
                      </FuseSvgIcon>
                      {t('GEOFENCES')}
                    </div>
                  </MenuItem>
                  <MenuItem value="C">
                    <div className="flex items-center gap-5">
                      <FuseSvgIcon color="inherit" size={18}>
                        material-solid:person
                      </FuseSvgIcon>
                      {t('USERS')}
                    </div>
                  </MenuItem>
                  <MenuItem value="U">
                    <div className="flex items-center gap-5">
                      <FuseSvgIcon color="inherit" size={18}>
                        material-solid:account_box
                      </FuseSvgIcon>
                      {t('CLIENTS')}
                    </div>
                  </MenuItem> */}
                  <MenuItem value="A">
                    <div className="flex items-center gap-5">
                      <FuseSvgIcon color="inherit" size={18}>
                        heroicons-solid:clipboard-check
                      </FuseSvgIcon>
                      {t('ASSIGNMENTS')}
                    </div>
                  </MenuItem>
                </Select>
              </FormControl>
            )}
          />
          <Controller
            name="file_data"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Box
                sx={{
                  backgroundColor: (theme) => lighten(theme.palette.background.default, 0.4),
                }}
                component="label"
                htmlFor="button-csvfile"
                className="productImageUpload border-4 border-dashed flex items-center justify-center relative w-full rounded-16 mb-24 overflow-hidden cursor-pointer shadow hover:shadow-lg"
                onDrop={onDropCsvFiles}
                onDragOver={(event) => event.preventDefault()}
              >
                <input
                  accept=".csv"
                  multiple={false}
                  className="hidden"
                  id="button-csvfile"
                  type="file"
                  onChange={onChangeCsvFile}
                />
                {value ? (
                  <div className="flex items-center justify-around px-1 py-6 w-full">
                    <FuseSvgIcon size={20}>feather:file-text</FuseSvgIcon>
                    <Typography variant="caption" className="text-8">
                      {value.name}
                    </Typography>
                    <Typography className="text-10 text-grey-500">{value.size}</Typography>
                  </div>
                ) : (
                  <div className="flex items-center h-128">
                    <FuseSvgIcon size={32} color="action">
                      heroicons-outline:upload
                    </FuseSvgIcon>
                  </div>
                )}
              </Box>
            )}
          />
          <div className="flex justify-end">
            <Button
              variant="contained"
              color="secondary"
              size="small"
              startIcon={<FuseSvgIcon size={20}>material-solid:file_upload</FuseSvgIcon>}
              disabled={!formState.isValid}
              onClick={uploadCsv}
            >
              {t('UPLOAD_CSV')}
            </Button>
          </div>
        </form>
      ) : (
        <div className="mt-12 px-12">
          <div className="flex items-center justify-between w-full">
            <FuseSvgIcon
              className={`text-48 ${csvLoad.status === 'P' ? 'animate-spin' : ''}`}
              size={20}
              style={{ color: FuseUtils.statusCsvEnum[csvLoad.status].color }}
            >
              {FuseUtils.statusCsvEnum[csvLoad.status].icon}
            </FuseSvgIcon>
            <Typography variant="subtitle1">
              {t('LOAD')} #{csvLoad.load_number}
            </Typography>
            <Box
              className="  text-white rounded-12 py-4 px-5  flex items-center gap-5"
              sx={{ backgroundColor: '#7500ef' }}
            >
              <FuseSvgIcon className="text-48" size={15}>
                {FuseUtils.typeCsvEnum[csvLoad.type].icon}
              </FuseSvgIcon>
              <Typography>{t(FuseUtils.typeCsvEnum[csvLoad.type].label)}</Typography>
            </Box>
            <Typography className="text-18 font-medium">{csvLoad?.name}</Typography>
          </div>
          <div className="w-full py-4">
            <Divider />
          </div>
          <div className="flex my-5">
            <Box className="bg-red-400 text-white rounded-12 p-5 flex items-center gap-5">
              <FuseSvgIcon className="text-48" size={15}>
                material-solid:error
              </FuseSvgIcon>
              <div className="flex">
                <Typography className="font-medium text-12">{t('FAILURE_COUNT')}:&nbsp;</Typography>
                <Typography className="text-12">{csvLoad.failure_count}</Typography>
              </div>
              {csvLoad.file_error_url && csvLoad.failure_count > 0 && (
                <Tooltip title={t('SHOW_CSV_ERRORS')}>
                  <IconButton
                    onClick={openCsvLoadErrorsModal}
                    variant="contained"
                    color="inherit"
                    size="small"
                  >
                    <FuseSvgIcon size={20}>material-solid:more_vert</FuseSvgIcon>
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </div>
          <div className="flex my-5">
            <Box className="bg-green-500 text-white rounded-12 p-5 flex items-center gap-5">
              <FuseSvgIcon className="text-48" size={15}>
                material-solid:check_circle
              </FuseSvgIcon>
              <Typography className="font-medium text-12">{t('SUCCESS_COUNT')}:</Typography>
              <Typography className="text-12">{csvLoad.success_count}</Typography>
            </Box>
          </div>
          <div className="flex flex-wrap">
            <Typography className="font-medium text-12">{t('LOADED_BY')}: </Typography>
            <Typography className="text-12">
              {csvLoad.user_data.name || csvLoad.user_data.last_name
                ? `${csvLoad.user_data.name} ${csvLoad.user_data.last_name}`
                : csvLoad.user_data.email}
            </Typography>
          </div>
          <div className="flex flex-wrap">
            <Typography className="font-medium text-12">{t('FILE_LOAD')}:</Typography>
            <Typography component={Link} to={csvLoad.file_url} className="text-12 cursor-pointer">
              {csvLoad.file_url?.split('/').pop()}
            </Typography>
          </div>
          <div className="flex flex-wrap">
            <Typography className="font-medium text-12">{t('FILE_SIZE')}:</Typography>
            <Typography className="text-12">
              {FuseUtils.getFileSizeLabel(csvLoad.file_size)}
            </Typography>
          </div>
          {checkRole('platform_support') && (
            <>
              <div className="w-full py-4">
                <Divider />
              </div>
              <div className="flex">
                <Typography className="font-medium text-10">{t('CREATION_DATE')}:</Typography>
                <Typography className="text-10">
                  {format(parseISO(csvLoad?.date_created), 'dd/MM/y hh:mm aaaa ')}
                </Typography>
              </div>
              <div className="flex">
                <Typography variant="caption" className="font-medium text-10">
                  {t('EDITION_DATE')}:
                </Typography>
                <Typography className="text-10">
                  {format(parseISO(csvLoad?.date_edited), 'dd/MM/y hh:mm aaaa ')}
                </Typography>
              </div>
              <div className="flex">
                <Typography variant="caption" className="font-medium text-10">
                  ID:
                </Typography>
                <Typography className="text-10">{csvLoad?.id}</Typography>
              </div>
            </>
          )}
        </div>
      )}
    </motion.div>
  );
}

export default CvsLoadSidebarContent;
